import React, { Component, lazy, Suspense } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { Flex } from '@rebass/grid'
import FreshworksSideNavigation from './components/FreshworksSideNavigation'
import RouteWithPrivileges from './components/RouteWithPrivileges'
import WindowTitle from './components/WindowTitle/WindowTitle'
import TopErrorBoundary from './components/TopErrorBoundary'
import { AppContainer } from './components/Layout'
import UserSession from './components/UserSession'
import PageNotFound from './components/Errors/PageNotFound'
import AppStore from './store/AppStore'
import { CONST, getQueryParams, updateDocumentDir, analyticsUtils } from './utils'
import retry from './utils/promiseRetry'
import ConnectivityChecker from './components/ConnectivityChecker'
import AppDataProvider from './components/AppDataProvider'
import { ProfileFieldsProvider } from './pages/Profile/ProfileFieldsContext'
// Further Enhancement: Move to a separate pages folder and use lazy loading
import SessionExists from './pages/MagicLinkLogin/SessionExists'

const Login = lazy(() => retry(() => import('./pages/Login')))
const ResetPassword = lazy(() => retry(() => import('./pages/ResetPassword')))
const SetPassword = lazy(() => retry(() => import('./pages/SetPassword')))
const ForgotPassword = lazy(() => retry(() => import('./pages/ForgotPassword')))
const RequestMagicLink = lazy(() => retry(() => import('./pages/RequestMagicLink')))
const Unlock = lazy(() => retry(() => import('./pages/Unlock')))
const ActivateUser = lazy(() => retry(() => import('./pages/ActivateUser')))
const MagicLinkLogin = lazy(() => retry(() => import('./pages/MagicLinkLogin')))
const AccountTransfer = lazy(() => retry(() => import('./pages/AccountTransfer')))
const ConfigureMFA = lazy(() => retry(() => import('./pages/ConfigureMFA')))

const Profile = lazy(() => retry(() => import('./pages/Profile')))
const Security = lazy(() => retry(() => import('./pages/Security')))
const Organisation = lazy(() => retry(() => import('./pages/Organisation')))
const Dashboard = lazy(() => retry(() => import('./pages/Dashboard')))
const Bills = lazy(() => retry(() => import('./pages/Bills')))
const UsersAndGroups = lazy(() => retry(() => import('./pages/Users')))
const MFAValidation = lazy(() => retry(() => import('./pages/Login/MFAValidation')))
const AuditLogs = lazy(() => retry(() => import('./pages/AuditLogs')))
const UpdatePassword = lazy(() => retry(() => import('./pages/Login/UpdatePassword')))
const MobileLoginConsent = lazy(() => retry(() => import('./pages/Login/MobileLoginConsent')))
const OAuthErrorPage = lazy(() => retry(() => import('./pages/OAuthErrorPage')))
const OauthConsent = lazy(() => retry(() => import('./pages/APIOauth/Consent/index')))
const SelectAccount = lazy(() => retry(() => import('./pages/APIOauth/SelectAccount/index')))
const ManageActiveSessions = lazy(() =>
  retry(() => import('./pages/Security/SessionManagement/ManageSessions/index'))
)

const redirectUrl = getQueryParams(CONST.REDIRECT_URI) || CONST.DEFAULT_REDIRECT_URL

const SCREEN_NAMES = analyticsUtils.SCREENS

function getComponent(Component, props = {}, withSuspense = true) {
  return function () {
    if (withSuspense) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Component {...props} />
        </Suspense>
      )
    }
    return <Component {...props} />
  }
}

const AnalyticsWrappedComponents = {
  AccountTransfer: getComponent(AccountTransfer),
  ActivateUser: getComponent(ActivateUser),
  AuditLogs: getComponent(AuditLogs),
  Bills: getComponent(Bills),
  ConfigureMFA: getComponent(ConfigureMFA),
  Dashboard: getComponent(Dashboard),
  ForgotPassword: getComponent(ForgotPassword),
  Login: getComponent(Login),
  MagicLinkLogin: getComponent(MagicLinkLogin),
  MFAValidation: getComponent(MFAValidation),
  MobileLoginConsent: getComponent(MobileLoginConsent),
  Organisation: getComponent(Organisation),
  Profile: getComponent(Profile, { redirectUrl }),
  RequestMagicLink: getComponent(RequestMagicLink),
  ResetPassword: getComponent(ResetPassword),
  Security: getComponent(Security),
  SetPassword: getComponent(SetPassword, {}, false),
  UpdatePassword: getComponent(UpdatePassword),
  Unlock: getComponent(Unlock),
  UsersAndGroups: getComponent(UsersAndGroups),
  SessionExists: getComponent(SessionExists),
  OAuthErrorPage: getComponent(OAuthErrorPage),
  OauthConsent: getComponent(OauthConsent),
  SelectAccount: getComponent(SelectAccount),
  ManageActiveSessions: getComponent(ManageActiveSessions)
}

// Initialize the store only once
const appStore = new AppStore()
class App extends Component {
  componentDidMount() {
    updateDocumentDir()
  }

  render() {
    return (
      <AppContainer>
        <ConnectivityChecker />
        <TopErrorBoundary>
          <AppDataProvider>
            <WindowTitle />
            <ProfileFieldsProvider>
              {/**
                IMPORTANT: Please add all NEW ROUTES to `route_config.js`
                to avoid unnecessary headaches and sleep peacefully
              */}
              <Switch>
                {/*
                  If a user loads the *root* route, the redirection url
                  depends on the presence of user session.
                  If it is present we need to load `/` (dashboard)
                  Otherwise we need to load `/login`
                  However the `/` route redirects to `/login` if the user
                  session does not exist. So, the absence of user session will be
                  handled by the `/` (dashboard) route.
                  */}
                <Route
                  exact
                  path="/login"
                  render={(props) =>
                    analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN,
                      AnalyticsWrappedComponents.Login,
                      props
                    )
                  }
                />

                <Route
                  exact
                  path={CONST.RE_AUTH_PATHNAME}
                  render={(props) =>
                    analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN,
                      AnalyticsWrappedComponents.Login,
                      props
                    )
                  }
                />

                <Route
                  path="/login/mfa"
                  render={(props) => {
                    const {
                      location: { state = {}, search },
                      history
                    } = props
                    if (!state[CONST.MFA.FROM_LOGIN]) {
                      history.push({ pathname: CONST.LOGIN_PATHNAME, search, state: {} })
                      return null
                    } else {
                      return analyticsUtils.getAnalyticsWrapper(
                        SCREEN_NAMES.LOGIN_MFA,
                        AnalyticsWrappedComponents.MFAValidation,
                        props
                      )
                    }
                  }}
                />

                <Route
                  exact
                  path="/login/update-password"
                  render={(props) => {
                    const {
                      location: { state = {}, search },
                      history
                    } = props
                    if (!state[CONST.MFA.FROM_LOGIN]) {
                      history.push({ pathname: CONST.LOGIN_PATHNAME, search, state: {} })
                      return null
                    } else {
                      return analyticsUtils.getAnalyticsWrapper(
                        SCREEN_NAMES.LOGIN_UPDATE_PASSWORD,
                        AnalyticsWrappedComponents.UpdatePassword,
                        props
                      )
                    }
                  }}
                />

                <Route
                  exact
                  path="/set-password/:hash"
                  render={(props) => (
                    <Suspense fallback={<div>Loading...</div>}>
                      <UserSession.DoesSessionExists>
                        {analyticsUtils.getAnalyticsWrapper(
                          SCREEN_NAMES.LOGIN_SET_PASSWORD,
                          AnalyticsWrappedComponents.SetPassword,
                          props
                        )}
                      </UserSession.DoesSessionExists>
                    </Suspense>
                  )}
                />

                <Route
                  exact
                  path="/forgot-password"
                  render={(props) =>
                    analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_FORGOT_PASSWORD,
                      AnalyticsWrappedComponents.ForgotPassword,
                      props
                    )
                  }
                />

                <Route
                  exact
                  path="/magiclink/request"
                  render={(props) =>
                    analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_MAGIC_LINK_REQUEST,
                      AnalyticsWrappedComponents.RequestMagicLink,
                      props
                    )
                  }
                />

                <Route
                  exact
                  path="/reset-password/:hash"
                  render={(props) => (
                    <UserSession.DoesSessionExists>
                      {analyticsUtils.getAnalyticsWrapper(
                        SCREEN_NAMES.LOGIN_RESET_PASSWORD,
                        AnalyticsWrappedComponents.ResetPassword,
                        props
                      )}
                    </UserSession.DoesSessionExists>
                  )}
                />

                <Route
                  exact
                  path="/invite/:hash"
                  render={(props) => (
                    <UserSession.DoesSessionExists>
                      {analyticsUtils.getAnalyticsWrapper(
                        SCREEN_NAMES.LOGIN_ACTIVATE_USER,
                        AnalyticsWrappedComponents.ActivateUser,
                        props
                      )}
                    </UserSession.DoesSessionExists>
                  )}
                />

                <Route
                  exact
                  path="/magiclink/:hash/login"
                  render={(props) =>
                    analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_MAGIC_LINK,
                      AnalyticsWrappedComponents.MagicLinkLogin,
                      props
                    )
                  }
                />

                <Route
                  exact
                  path="/unlock/:hash"
                  render={(props) =>
                    analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_UNLOCK,
                      AnalyticsWrappedComponents.Unlock,
                      props
                    )
                  }
                />

                <Route
                  exact
                  path="/transfers/approval"
                  render={(props) =>
                    analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.ACCOUNT_TRANSFER_APPROVAL,
                      AnalyticsWrappedComponents.AccountTransfer,
                      props
                    )
                  }
                />

                <Route
                  exact
                  path="/mfa/setup"
                  render={(props) => {
                    const {
                      location: { state = {}, search }
                    } = props
                    if (state[CONST.MFA.FROM_LOGIN] || state[CONST.MFA.FROM_INVITE]) {
                      return analyticsUtils.getAnalyticsWrapper(
                        SCREEN_NAMES.LOGIN_MFA_SETUP,
                        AnalyticsWrappedComponents.ConfigureMFA,
                        props
                      )
                    } else {
                      return (
                        <React.Fragment>
                          <UserSession.IfUserSessionExists>
                            {analyticsUtils.getAnalyticsWrapper(
                              SCREEN_NAMES.LOGIN_MFA_SETUP,
                              AnalyticsWrappedComponents.ConfigureMFA,
                              props
                            )}
                          </UserSession.IfUserSessionExists>
                          <UserSession.IfUserSessionAbsent>
                            <Redirect to={{ pathname: CONST.LOGIN_PATHNAME, search, state: {} }} />
                          </UserSession.IfUserSessionAbsent>
                        </React.Fragment>
                      )
                    }
                  }}
                />

                <Route exact path="/session-exists">
                  {AnalyticsWrappedComponents.SessionExists}
                </Route>

                <RouteWithPrivileges.LoggedIn
                  analyticsIdentity="login.consent"
                  exact
                  path="/login-consent">
                  {AnalyticsWrappedComponents.MobileLoginConsent}
                </RouteWithPrivileges.LoggedIn>

                <RouteWithPrivileges.LoggedIn
                  analyticsIdentity="OAuthErrorPage"
                  path="/authorize/error">
                  {AnalyticsWrappedComponents.OAuthErrorPage}
                </RouteWithPrivileges.LoggedIn>

                <RouteWithPrivileges.LoggedIn
                  analyticsIdentity="APIOauth"
                  exact
                  path="/authorize/consent"
                  searchParam={`?redirect_uri=${encodeURIComponent(window.location.href)}`}>
                  {AnalyticsWrappedComponents.OauthConsent}
                </RouteWithPrivileges.LoggedIn>

                <RouteWithPrivileges.LoggedIn
                  analyticsIdentity="APIOauth"
                  exact
                  path="/authorize/account-select"
                  searchParam={`?redirect_uri=${encodeURIComponent(window.location.href)}`}>
                  {AnalyticsWrappedComponents.SelectAccount}
                </RouteWithPrivileges.LoggedIn>

                {/* List routes which require user session here */}
                <Route>
                  <React.Fragment>
                    <Flex>
                      {/*
                          /* routes which require the sidebar
                          /* are the ones which requires user session to exist with
                          /* some exceptions like when a user session exists and the
                          /* `/invite` and `/reset-password` is visited
                          /* we want the sidebar **not** to unmount during route changes
                          /* unmounting results in repeatedly adding the omnibar web
                          /* component which isuues API request
                          /* so we want to prevent API calls for every route transition
                        */}
                      <UserSession.IfUserSessionExists>
                        <FreshworksSideNavigation />
                      </UserSession.IfUserSessionExists>
                      <Switch>
                        <RouteWithPrivileges.LoggedIn analyticsIdentity="dashboard" exact path="/">
                          {AnalyticsWrappedComponents.Dashboard}
                        </RouteWithPrivileges.LoggedIn>

                        <RouteWithPrivileges.LoggedIn
                          exact
                          path="/activate-day-pass/consent/bundle/:bundleId">
                          {AnalyticsWrappedComponents.Dashboard}
                        </RouteWithPrivileges.LoggedIn>

                        <RouteWithPrivileges.IfPrivilegesExist
                          privileges={CONST.PRIVILEGES_KEYS.SECURITY}
                          analyticsIdentity="ManageActiveSessions"
                          path="/security/agents/session-management/manage-sessions">
                          {AnalyticsWrappedComponents.ManageActiveSessions}
                        </RouteWithPrivileges.IfPrivilegesExist>

                        <RouteWithPrivileges.LoggedIn analyticsIdentity="profile" path="/profile">
                          {AnalyticsWrappedComponents.Profile}
                        </RouteWithPrivileges.LoggedIn>

                        {/* We added analytics wrapper inside subscription */}
                        <RouteWithPrivileges.LoggedIn
                          analyticsIdentity="subscriptions"
                          path="/subscriptions">
                          {AnalyticsWrappedComponents.Bills}
                        </RouteWithPrivileges.LoggedIn>

                        <RouteWithPrivileges.IfPrivilegesExist
                          privileges={CONST.PRIVILEGES_KEYS.SECURITY}
                          analyticsIdentity="security"
                          path="/security">
                          {AnalyticsWrappedComponents.Security}
                        </RouteWithPrivileges.IfPrivilegesExist>

                        <RouteWithPrivileges.IfAdmin
                          analyticsIdentity="organisation"
                          exact
                          path="/organization">
                          {AnalyticsWrappedComponents.Organisation}
                        </RouteWithPrivileges.IfAdmin>

                        <RouteWithPrivileges.IfPrivilegesExist
                          privileges={CONST.PRIVILEGES_KEYS.USER_MANAGEMENT}
                          analyticsIdentity="userAndGroups"
                          path="/users-groups">
                          {AnalyticsWrappedComponents.UsersAndGroups}
                        </RouteWithPrivileges.IfPrivilegesExist>

                        <RouteWithPrivileges.IfPrivilegesExist
                          privileges={CONST.PRIVILEGES_KEYS.AUDIT_LOGS}
                          analyticsIdentity="auditLogs"
                          path="/audit-logs">
                          {AnalyticsWrappedComponents.AuditLogs}
                        </RouteWithPrivileges.IfPrivilegesExist>

                        {/* For unknown routes always redirect to `/` (dashboard)  */}
                        <Route render={() => <PageNotFound />} />
                      </Switch>
                    </Flex>
                  </React.Fragment>
                </Route>
              </Switch>
            </ProfileFieldsProvider>
          </AppDataProvider>
        </TopErrorBoundary>
      </AppContainer>
    )
  }
}

export { appStore }
export default withRouter(App)
