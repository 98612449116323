import React, { Fragment, useContext } from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter, Link, NavLink } from 'react-router-dom'
import styled, { css } from 'react-emotion'
import { Flex, Box } from './../Grid'
import {
  getProfileRouteAndEditParams,
  getUserName,
  closeRts,
  CONST,
  isChangePasswordAllowed,
  getTitleRoute,
  getPageTitleTranslationPath
} from '../../utils'
import { cssWithRTL, styledWithRTL } from './../../utils/styles'
import { getLogoutUrlWithPostLogoutRedirectUrl } from './../../apis'
import delve from 'dlv'
import HeaderContentBox, { HEADER_HEIGHT, ACTUAL_HEADER_HEIGHT } from '../Layout/HeaderContentBox'
import IntlContext, { useIntl } from '../IntlContext'
import Menu from '../../components/Menu'
import ProfilePicture from '../../components/ProfilePicture'
import { PrimaryText, H1, SecondaryText, Anchor } from '../../components/Typography'
import { BreadcrumbsFromContext } from './../Breadcrumbs'
import { AppStateContext } from '../AppDataProvider'
import { getDataFromAppStateContext } from '../AppDataProvider/AppStateContext'
import { ProfileFields } from '../../pages/Profile/ProfileFields'
import Icon from '../Icon'
import { useLocaleState } from '../../utils/LanguageProvider'
import { useUserPrivileges } from '../RBACProvider'

const RoundedNotification = styled(Flex)`
  background-color: ${({
    theme: {
      palette: { c }
    }
  }) => c[700]};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 4px;
  margin-top: 2px;
  font-size: 8px;
`
const StyledMenuButton = styledWithRTL('button')`
  background-color: white;
  border: none;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 20px;
  cursor: pointer;
`
const textAlignClass = cssWithRTL`
text-align: left;
`
const textOverFlowStyle = {
  'text-overflow': 'ellipsis',
  'max-width': '20rem',
  overflow: 'hidden'
}
const StyledMenuItem = styled(Menu.Item)`
  margin-left: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.08px;
`

const getNavStyle = (isActive, theme) => ({
  color: isActive ? theme.palette.a[900] : theme.palette.e[900]
})

const cursorPointer = css`
  cursor: pointer;
`

const StyledMenuBox = styled(Menu.Item)`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.08px;
`
const offSetY = 23
const marginHamburger = 14

const StyledMenuContainer = styledWithRTL(Box)`
  width: 200px;
  margin: 8px;
  height: calc(100vh - ${HEADER_HEIGHT + marginHamburger + offSetY}px );
`
const LogoutLink = withRouter(({ toIntlString }) => {
  const logoutUrl = getLogoutUrlWithPostLogoutRedirectUrl()
  return (
    <Anchor href={logoutUrl} role="menuitem">
      <PrimaryText textType="error" className={textAlignClass}>
        {toIntlString('my_profile.logout')}
      </PrimaryText>
    </Anchor>
  )
})

const isActiveRoute = (to) => {
  const { pathname } = window.location || {}
  return pathname === to
}

const ShowHamburger = withTheme(({ navList, theme }) => {
  const { toIntlString } = useIntl()
  const [{ isRTLLanguage }] = useLocaleState()
  return (
    <Flex>
      <Menu
        trigger={({ selected }) => (
          <Flex className={cursorPointer} data-testid="addr_menu">
            <Icon name="hamburger" width={20} />
          </Flex>
        )}
        offsetX={70 * (isRTLLanguage ? -1 : 1)}
        offsetY={offSetY}>
        {(close) => (
          <StyledMenuContainer>
            {navList.map((navItem) => {
              const {
                to,
                displayNameTranslationKey,
                iconName,
                hasSubLinks,
                subRoutes,
                notificationCount
              } = navItem
              return (
                <>
                  {!hasSubLinks ? (
                    <NavLink
                      to={to}
                      exact
                      onClick={close}
                      style={getNavStyle(false, theme)}
                      activeStyle={getNavStyle(true, theme)}
                      isActive={(match, location) => {
                        const { pathname } = location || {}
                        return pathname === to
                      }}>
                      <StyledMenuBox>
                        <Flex alignItems="center">
                          {iconName && (
                            <Icon
                              name={`${iconName}${isActiveRoute(to) ? '-active' : ''}`}
                              width={20}
                            />
                          )}
                          <Box ml="10px">{toIntlString(displayNameTranslationKey)}</Box>
                          {!isActiveRoute(to) && !!notificationCount && (
                            <RoundedNotification data-testid="round_notification">
                              {notificationCount}
                            </RoundedNotification>
                          )}
                        </Flex>
                      </StyledMenuBox>
                    </NavLink>
                  ) : (
                    <>
                      <StyledMenuBox>
                        <Flex alignItems="center">
                          {iconName && <Icon name={iconName} width={20} />}
                          <Box ml="10px">{toIntlString(displayNameTranslationKey)}</Box>
                        </Flex>
                      </StyledMenuBox>
                      {subRoutes.map((subRoute) => {
                        const { displayNameTranslationKey, to } = subRoute
                        return (
                          <NavLink
                            onClick={close}
                            to={to}
                            style={getNavStyle(false, theme)}
                            activeStyle={getNavStyle(true, theme)}
                            isActive={(match, location) => {
                              const { pathname } = location || {}
                              return pathname === to
                            }}>
                            <StyledMenuItem>
                              <Flex alignItems="center">
                                <Box mr="8px">{toIntlString(displayNameTranslationKey)}</Box>
                                <Icon
                                  rotateDeg={-90}
                                  name={isActiveRoute(to) ? 'chevron-up-blue' : 'chevron-up'}
                                  width={10}
                                />
                              </Flex>
                            </StyledMenuItem>
                          </NavLink>
                        )
                      })}
                    </>
                  )}
                </>
              )
            })}
          </StyledMenuContainer>
        )}
      </Menu>
    </Flex>
  )
})

const Avatar = ({ toIntlString, isOpen }) => {
  return (
    <StyledMenuButton
      aria-haspopup="menu"
      aria-expanded={isOpen}
      aria-label={toIntlString('dashboard.profile_menu')}>
      <ProfilePicture size="medium" isActive={true} data-testid="user-profile-menu" />
    </StyledMenuButton>
  )
}

const Header = ({
  location: { pathname },
  showBreadcrumbs = false,
  showHamburgerIcon = false,
  navList = []
}) => {
  const route = getTitleRoute(pathname)
  const [getCurrentUserDetails, getOrgSummary] = getDataFromAppStateContext(
    useContext(AppStateContext),
    ['getCurrentUserDetails', 'getOrgSummary'],
    [{}, {}]
  )
  const authModuleConfig = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getAllowedLoginMethods',
    {}
  )
  const passwordModule = delve(
    authModuleConfig,
    `modules.${CONST.AUTHENTICATION_METHODS.PASSWORD}`,
    {}
  )
  let userEmail = ''
  let restOfUser = {}
  if (!!getCurrentUserDetails.data) {
    const { email, ...rest } = getCurrentUserDetails.data.user
    userEmail = email
    restOfUser = rest
  }
  const doesSecurityPrivilegesExist = useUserPrivileges(
    CONST.PRIVILEGES_KEYS.USER_LEVEL_SECURITY_MANAGEMENT.READ
  )
  const canShowSecurityTab = isChangePasswordAllowed(
    doesSecurityPrivilegesExist,
    passwordModule.enabled
  )
  const getViewOrEditProfileText = (isAnyFieldEditable) =>
    `dashboard.${isAnyFieldEditable ? 'edit_profile' : 'view_profile'}`

  return (
    <IntlContext.Consumer>
      {({ toIntlString }) => (
        <HeaderContentBox>
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center" width={1}>
            <Box ml={3}>
              <Flex>
                {showHamburgerIcon && navList.length > 0 && (
                  <Box ml={20} mr={30}>
                    <ShowHamburger navList={navList} />
                  </Box>
                )}
                {showBreadcrumbs ? (
                  <BreadcrumbsFromContext />
                ) : (
                  <H1 overrideTag={'h5'}>
                    {route
                      ? `${toIntlString(getPageTitleTranslationPath(route, getOrgSummary))}`
                      : ''}
                  </H1>
                )}
              </Flex>
            </Box>
            <Flex flexDirection="row-reverse" alignItems="center">
              <ProfileFields>
                {({ isAnyFieldEditable }) => (
                  <Menu
                    position="bottom right"
                    trigger={(isOpen) => {
                      return (
                        <Box>
                          <Avatar toIntlString={toIntlString} isOpen={isOpen} />
                        </Box>
                      )
                    }}>
                    {(close) => (
                      <Fragment>
                        <Menu.Header>
                          <Flex flexDirection="row">
                            <Box m={2}>
                              <ProfilePicture
                                size="medium"
                                data-testid="header_menu_profile_image"
                              />
                            </Box>
                            <Box p={2} pl={1}>
                              <PrimaryText
                                textWeight="bold"
                                data-testid="header_menu_name"
                                style={textOverFlowStyle}>
                                {getUserName(restOfUser)}
                              </PrimaryText>
                              <SecondaryText textType="label" data-testid="header_menu_email">
                                {userEmail}
                              </SecondaryText>
                            </Box>
                          </Flex>
                        </Menu.Header>
                        <Box role="menu">
                          <Menu.Item
                            onClick={close}
                            data-testid={getViewOrEditProfileText(isAnyFieldEditable)}>
                            <Link
                              to={getProfileRouteAndEditParams(isAnyFieldEditable)}
                              role="menuitem">
                              <PrimaryText className={textAlignClass}>
                                {toIntlString(getViewOrEditProfileText(isAnyFieldEditable))}
                              </PrimaryText>
                            </Link>
                          </Menu.Item>
                          {canShowSecurityTab && (
                            <Menu.Item onClick={close} data-testid="dashboard.security_settings">
                              <Link to="/profile/change-password" role="menuitem">
                                <PrimaryText className={textAlignClass}>
                                  {toIntlString('dashboard.security_settings')}
                                </PrimaryText>
                              </Link>
                            </Menu.Item>
                          )}
                          <Menu.Item
                            onClick={() => {
                              close()
                              closeRts()
                            }}
                            data-testid="my_profile.logout">
                            <LogoutLink toIntlString={toIntlString} />
                          </Menu.Item>
                        </Box>
                      </Fragment>
                    )}
                  </Menu>
                )}
              </ProfileFields>

              {/* "Send Feedback" button has removed from the support accounts. So, we removed from Org admin page for now, in future if we require we will use same component. */}
              {/* <FeedbackBot /> */}
            </Flex>
          </Flex>
        </HeaderContentBox>
      )}
    </IntlContext.Consumer>
  )
}

export default withRouter(React.memo(Header))

export { HEADER_HEIGHT, ACTUAL_HEADER_HEIGHT }
