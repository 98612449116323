import {
  ArrayUtils,
  getCloudTypeListByBundleType,
  getDisplayRankForOffering,
  isCloudTypeSupportedBundleType
} from './../../utils'
import CONST from '../../utils/constants'

const ACCOUNT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED'
}

const getBundleAttributes = (account, bundleTypeList = [], cloudTypesMapById = new Map()) => {
  let accountAttributes = { name: '', src: 'freshworks', displayRank: CONST.DEFAULT_DISPLAY_RANK }
  if (bundleTypeList.length === 0) {
    return accountAttributes
  }

  bundleTypeList.some((bundleType) => {
    return bundleType.bundles.some((bundle) => {
      if (account.bundleIdentifier === bundle.id) {
        if (
          isCloudTypeSupportedBundleType(bundleType) &&
          bundle.cloudTypeIdentifier &&
          cloudTypesMapById.has(bundle.cloudTypeIdentifier)
          // for backward compatibility
          // if cloud_type_identifier is not present,
          // take properties from bundle_type entity
        ) {
          const bundleCloudType = cloudTypesMapById.get(bundle.cloudTypeIdentifier)
          accountAttributes.src = bundleCloudType.name.toLowerCase()
          accountAttributes.displayRank = getDisplayRankForOffering(bundleCloudType)
        } else {
          accountAttributes.src = bundleType.name.toLowerCase()
          accountAttributes.displayRank = getDisplayRankForOffering(bundleType)
        }
        accountAttributes.name = bundle.name
        return true
      }
    })
  })
  return accountAttributes
}

const shouldRenderAccount = (account) => {
  const { bundleIdentifier, anchor } = account
  return (
    !bundleIdentifier || // if No bundleIdentifier, this means the account is not part of Bundles
    !!anchor
  ) // account part of bundles, but we should show only the main accounts
}

const getAllAccountsFromProductsArray = (productList = []) => {
  return [].concat.apply(
    [],
    productList.map((product) => product.accounts)
  )
}

const getAllUnsubscribedAccount = (allAccountsInOrg = [], allAccountsSubscribedByTheUser = []) => {
  const allSubscribedDomains = allAccountsSubscribedByTheUser.map((account) => account.domain),
    unSubscribedAccounts = allAccountsInOrg.filter((account) => {
      // filter out accounts not present in subscribed accounts
      if (allSubscribedDomains.indexOf(account.domain) === -1) {
        return true
      }

      return false
    })
  return unSubscribedAccounts
}

const getAccountTransferPayload = (status, account = {}, comments = '') => {
  const { id: transfer_id, exportingAccountDomain: exporting_account_domain } = account
  return {
    comments,
    transfer_id,
    exporting_account_domain,
    status
  }
}

const getAllProductsWithNameAndDisplayName = (productList = []) => {
  return productList.filter(({ displayName, name }) => displayName && name)
}

/**
 * @Author Sumeet Sood
 * @param {*} bundleTypeList //bundleTypes
 * @param {*} cloudTypeList //cloudTypes
 * @returns {Array} If a bundleType has cloud type,then cloud types are added in array, otherwise bundleType is added to array
 */
const getAllFreshworksBundleOfferings = (bundleTypeList, cloudTypeList) => {
  let allBundleAndCloudOfferings = []
  const cloudTypeListByBundleType = getCloudTypeListByBundleType(cloudTypeList)
  bundleTypeList.forEach((bundleType) => {
    if (
      isCloudTypeSupportedBundleType(bundleType) &&
      cloudTypeListByBundleType[bundleType.id] &&
      cloudTypeListByBundleType[bundleType.id] instanceof Array
    ) {
      cloudTypeListByBundleType[bundleType.id].forEach((cloudType) => {
        allBundleAndCloudOfferings.push({
          ...cloudType,
          bundleMarketingUrl: cloudType.marketingUrl,
          addAccountUrl: cloudType.addAccountUrl,
          entityType: CONST.BUNDLE_OFFERING_TYPE.CLOUD_TYPE
        })
      })
    } else {
      allBundleAndCloudOfferings.push({
        ...bundleType,
        entityType: CONST.BUNDLE_OFFERING_TYPE.BUNDLE
      })
    }
  })
  return allBundleAndCloudOfferings
}

const filterCloudsWithSingleBundleSubscription = (bundlesToExplore, freshworksBundlesMap) => {
  return bundlesToExplore.filter((bundle) => {
    const { entityType, bundleTypeId } = bundle
    if (entityType === CONST.BUNDLE_OFFERING_TYPE.CLOUD_TYPE) {
      const parentBundle = freshworksBundlesMap.get(bundleTypeId)
      if (parentBundle.isSubscribed && parentBundle.allowMultipleSignup === false) {
        return false
      }
    }
    return true
  })
}

const exploreFreshworksProductsList = (
  freshworksProducts = [],
  freshworksBundles = [],
  freshworksCloudTypes = [],
  subscribedAccounts,
  subscribedBundles
) => {
  const allProductsMap = ArrayUtils.mapByKey(freshworksProducts, 'id')
  const allBundleTypesMap = ArrayUtils.mapByKey(freshworksBundles, 'id')
  const allFreshworksBundleOfferings = getAllFreshworksBundleOfferings(
    freshworksBundles,
    freshworksCloudTypes
  )
  const allFreshworksBundleOfferingsMap = ArrayUtils.mapByKey(allFreshworksBundleOfferings, 'id')
  const bundleIdParentTypeMap = {}
  subscribedBundles.forEach((bundle) => {
    bundle.bundles.forEach((bundleInstance) => {
      let parentTypeIdentifier
      bundleIdParentTypeMap[bundleInstance.id] = {}
      if (!!bundleInstance.cloudTypeIdentifier) {
        parentTypeIdentifier = bundleInstance.cloudTypeIdentifier
        bundleIdParentTypeMap[bundleInstance.id].type = CONST.BUNDLE_OFFERING_TYPE.CLOUD_TYPE
        // Flaging a bundle_type as subscribed if any of its cloud_type is subscribed
        allBundleTypesMap.get(bundleInstance.bundleTypeIdentifier).isSubscribed = true
      } else {
        parentTypeIdentifier = bundleInstance.bundleTypeIdentifier
        bundleIdParentTypeMap[bundleInstance.id].type = CONST.BUNDLE_OFFERING_TYPE.BUNDLE
      }
      bundleIdParentTypeMap[bundleInstance.id].parentId = parentTypeIdentifier
      if (allFreshworksBundleOfferingsMap.has(parentTypeIdentifier)) {
        allFreshworksBundleOfferingsMap.get(parentTypeIdentifier).isSubscribed = true
      }
    })
  })
  subscribedAccounts.forEach((account) => {
    if (!account.bundleIdentifier && allProductsMap.has(account.productId)) {
      allProductsMap.get(account.productId).isSubscribed = true
    } else if (
      account.bundleIdentifier &&
      account.anchor &&
      bundleIdParentTypeMap[account.bundleIdentifier]
    ) {
      const parentTypeId = bundleIdParentTypeMap[account.bundleIdentifier].parentId
      const parentTypeEntity = allFreshworksBundleOfferingsMap.get(
        bundleIdParentTypeMap[parentTypeId]
      )
      const useProductSignupConfig =
        !!parentTypeEntity && parentTypeEntity.entityType === CONST.BUNDLE_OFFERING_TYPE.BUNDLE
      if (allProductsMap.get(account.productId) && useProductSignupConfig) {
        allProductsMap.get(account.productId).isSubscribed = true
      }
    }
  })
  const productsToExplore = freshworksProducts.filter((product) => {
    // Products already subscribed by the org which have add_account_url
    // or the ones that are not subscribed and have marketing_url should
    // be displayed in Explore Freshworks Products Section
    const productDef = allProductsMap.get(product.id)
    return (
      (productDef.isSubscribed && productDef.addAccountUrl) ||
      (!productDef.isSubscribed && productDef.marketingUrl)
    )
  })
  const bundlesToExplore = allFreshworksBundleOfferings.filter((offering) => {
    if (offering.entityType === CONST.BUNDLE_OFFERING_TYPE.BUNDLE) {
      //Ignore rendering the bundle if using product signup
      return false
    }
    // As of now only bundles having cloud_types support adding accounts
    return (
      (!offering.isSubscribed && offering.bundleMarketingUrl) ||
      (offering.isSubscribed && offering.addAccountUrl)
    )
  })
  return [
    ...productsToExplore,
    ...filterCloudsWithSingleBundleSubscription(bundlesToExplore, allBundleTypesMap)
  ].sort((offeringA, offeringB) => {
    return getDisplayRankForOffering(offeringB) - getDisplayRankForOffering(offeringA)
  })
}

// To check whether a given account is a cloudType or bundleType or standalone
// and return list of the same type items and the specific account's typeId
// Type => (bundle, cloud, standalone product)
const getAccountTypeListAndProductIdForAGivenAccount = (
  cloudTypeList,
  bundleTypeList,
  freshworksProductList,
  account
) => {
  let id,
    productList = []
  if (!!account.cloudTypeId) {
    productList = cloudTypeList
    id = account.cloudTypeId
  } else if (!!account.bundleTypeId) {
    productList = bundleTypeList
    id = account.bundleTypeId
  } else {
    productList = freshworksProductList
    id = account.productId
  }
  return {
    productList,
    id
  }
}

const getProductIconAndDisplayName = (productList, productId) => {
  const product = productList.filter((product) => product.id === productId)
  return {
    src: (product[0]?.name || '').toLowerCase(),
    productName: product[0]?.displayName || ''
  }
}

const isAnyValidTransferRequestAvailable = ({
  accounts,
  getAllProductsFromCache,
  bundleTypeList,
  cloudTypesList
}) => {
  for (let account of accounts) {
    const { productList, id } = getAccountTypeListAndProductIdForAGivenAccount(
      cloudTypesList,
      bundleTypeList,
      getAllProductsFromCache,
      account
    )
    const { src, productName } = getProductIconAndDisplayName(productList, id)
    const isValidProduct = !!src && !!productName
    if (isValidProduct) {
      return true
    }
  }
  return false
}

const isDayPassEnabled = (selectedAccount, bundleTypeList = [], cloudTypesList) => {
  if (bundleTypeList.length === 0) {
    return false
  }
  const selectedAccountsBundleId = !!selectedAccount && selectedAccount.bundleIdentifier
  let cloudTypeId

  if (!!bundleTypeList) {
    for (let bundlesList = 0; bundlesList < bundleTypeList.length; bundlesList++) {
      if (bundleTypeList[bundlesList].id === selectedAccountsBundleId) {
        cloudTypeId = bundleTypeList[bundlesList].cloudTypeIdentifier
      }
      for (let cloudList = 0; cloudList < cloudTypesList.length; cloudList++) {
        if (cloudTypeId === cloudTypesList[cloudList].id) {
          return cloudTypesList[cloudList].daypassEnabled
        }
      }
    }
    return false
  }
}

export {
  getBundleAttributes,
  shouldRenderAccount,
  getAllAccountsFromProductsArray,
  getAllUnsubscribedAccount,
  ACCOUNT_STATUS,
  getAccountTransferPayload,
  getAllProductsWithNameAndDisplayName,
  exploreFreshworksProductsList,
  getProductIconAndDisplayName,
  getAccountTypeListAndProductIdForAGivenAccount,
  isAnyValidTransferRequestAvailable,
  isDayPassEnabled
}
